<template>
  <DetailsContainer :title="article.title" :content="article.content" />
</template>

<script>
import DetailsContainer from "@/views/layout/DetailsContainer";

import { useRouter } from "vue-router"; //引入路由
import { onMounted, reactive, toRefs } from "vue";
import axios from "@/api/apis";

export default {
  name: "TechssDetails",
  components: { DetailsContainer },
  created() {},
  setup() {
    const router = useRouter();
    const state = reactive({
      article: {
        title: "",
        content: "",
      },
    });
    const loadArticle = () => {
      axios
        .post("/article", { id: router.currentRoute.value.query.id })
        .then(function (data) {
          state.article.title = data.title;
          state.article.content = data.content;
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    onMounted(() => {
      loadArticle();
    });
    return {
      ...toRefs(state),
    };
  },
  methods: {},
};
</script>

<style scoped>
</style>
